<template>
    <div class="page-container">
        <div class="banner-content">
            <div class="banner">
                <img :src="bannerUrl" alt="">
            </div>
            <div class="page-title-content">
                <div class="page-title">
                    <div class="first-title">最新资讯</div>
                    <div class="two-title">一手掌握</div>
                </div>
            </div>
        </div>
        <div class="page-content-div">
            <div class="page-content">
                <div class="xiangqing-content">
                    <div class="back-content" @click="backTo">返回</div>
                    <p class="module-title">{{detailInfo.title}}</p>
                    <div class="date">丹业包装  {{detailInfo.newsTime}}</div>

                    <!--<div class="text-div">
                        <div>
                            江苏丰山集团股份有限公司成为2020年纸箱利润规模最大的中国公司，利润高达9840.34万元，广东东方精工科技股份有限公司紧随其后，利润达到6098.8万元。
                        </div>
                        <div>
                            2020年中国纸箱上市公司利润规模排行榜（一季度）数据统计显示，前9家利润总额企业中，注册地址在西安市的企业数量最多，共有1家企业上榜，占据了榜单总数的11.11%；注册地址在荆门市的企业数量排在第二，共有1家企业上榜，其次是盐城市、汕头市等地区，分别有1家、1家。
                        </div>
                        <div>
                            2020年中国纸箱上市公司利润规模排行榜（一季度）数据统计显示，中国上市公司中注册地在西安市的纸箱企业利润总额高达9840.34万元，利润贡献率为28.33%。其次是佛山市和嘉兴市地区，上榜企业的利润总额分别为6098.8万元和4588.59万元，三地的头部效应明显。厦门市、唐山市、西安市、荆门市等地区上榜企业的利润总额合计为14201.75万元，总体贡献率为40.89%。
                        </div>
                    </div>-->
                    <div class="image-div">
                        <img :src="detailInfo.imgUrl" alt="">
                    </div>
                    <div class="text-div" v-html="detailInfo.detail">
                        <!--{{detailInfo.detail}}-->
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                newsId: 0,
                detailInfo: {},
                bannerUrl: ''
            }
        },
        mounted() {
            const banners = JSON.parse(localStorage.getItem('banners'));
            banners ? this.bannerUrl = banners[7]['imgUrl'] : this.bannerUrl = this.$url.backImg[7];

            this.newsId = this.$route.query.id;
            this.getInfoDetail();
        },
        methods: {
            backTo() {
                this.$router.go(-1)
            },
            getInfoDetail() {
                const url = this.$url.getBackendApiUrl(`/api/news/detail?newsId=${this.newsId}`);
                this.$http.get(url).then(res => {
                    this.detailInfo = res.data.data;
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .page-content-div{
        display: flex;
        justify-content: center;
        width: 100%;
        .page-content{
            width: 1200px;
        }
    }
    .module-title{
        color: rgba(38, 39, 42, 100);
        font-size: 26px;
        margin-bottom: 20px;
        margin-top: 30px;
        font-family: PingFangSC-Semibold;
    }
    .date{
        color: rgba(193, 193, 200, 100);
        font-size: 14px;
        margin-bottom: 24px;
        font-family: PingFangSC-Regular;
    }
    .xiangqing-content{
        .text-div{
            line-height: 30px;
            color: rgba(114, 114, 122, 100);
            font-size: 16px;
            font-family: PingFangSC-Regular;
        }
        .image-div{
            height: 400px;
            margin: 20px 0;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
    .back-content{
        cursor: pointer;
        border: 1px solid rgba(228, 228, 235, 1);
        height: 44px;
        width: 139px;
        line-height: 44px;
        text-align: center;
        margin-top: 30px;
        &:hover{
            border-color: rgba(57, 63, 227, 1);
            color: rgba(57, 63, 227, 100);
        }
    }
</style>